.welcome-container-appq {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align the form at the top */
    min-height: 100vh; /* Ensure it covers the full viewport initially */
    padding: 20px 0; /* Add vertical padding for spacing */
    background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
    font-family: Arial, sans-serif;
    box-sizing: border-box; /* Include padding in the height calculation */
}

/* Form container */
.form-container-appq {
    background-color: #fff5e6;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 700px;
    width: 100%;
    height: 85vh;
    position: absolute; /* Ensures the top stays fixed */
    top: 10%; /* Fixes the top position at 25% of the viewport height */
    left: 50%; /* Centers the container horizontally */
    transform: translateX(-50%); /* Centers the container by adjusting the horizontal position */
    box-sizing: border-box; /* Ensures padding doesn't affect width */
    overflow-y: auto;
}

/* Title styling */
.form-container-appq h1 {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Form group */
.form-group-appq {
    margin-bottom: 20px;
}

/* Label styling */
.form-label-appq {
    display: block;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

/* Text area styling */
.form-textarea-appq {
    width: 100%;
    min-height: 100px;
    padding: 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fffbf3;
    resize: vertical;
    transition: border-color 0.3s ease;
}

.form-textarea-appq:focus {
    border-color: #f2994a;
    outline: none;
    box-shadow: 0 0 5px rgba(242, 153, 74, 0.5);
}
