/* src/BusinessesPage.css */

.businesses-page {
  display: flex;
  height: 100vh;
  background-color: #FFE4C7; /* Set the main background to dark beige */
}

.sidebar {
  width: 250px;
  background-color: #f9e4e4; /* Sidebar background color */
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Add margin on the left */
  margin-top: 50px; /* Reduced from 110px */
  margin-left: 10px;
}

.sidebar-header h2 {
  font-size: 32px;
  font-weight: 600;
}

.sidebar-nav {
  flex-grow: 1;
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  padding: 15px 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the buttons take full width */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  flex-direction: row; /* Ensure the arrow is on the right side */
  color: gray; /* Set text color to gray */
}
.sidebar-nav li::after {
  content: "\f061";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-left: 10px;
}

.sidebar-nav li.active {
  background-color: #007bff;
  color: #fff;
}

.sidebar-nav li:hover {
  background-color: #e9ecef;
}

.sidebar-footer {
  padding: 20px;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  color: gray; /* Set text color to gray */
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.empty-job-titles{
  margin-top: 40vh;
  margin-left: 50vh;

  font-size: 24px;
  font-weight: bold;
  color: darkgray

}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #FFE4C7; /* Replace gray overlay with #FFE4C7 */
  margin-top: 55px; /* Reduced from 110px */
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.position-dropdown-container {
  display: inline-flex;
  align-items: center; /* Center align items vertically */
  padding: 3px 10px; /* Adjust padding to make it less tall */
  border-radius: 15px;
  background-color: #fff8e3; /* Light beige background color */
  width: auto; /* Adjust width to accommodate text */
  height: 40px;
  margin-top: 10px; /* Reduced from 10px */
}

.position-dropdown-container select {
  border: none;
  background-color: transparent;
  margin-left: 5px;
  font-size: 1em;
  font-weight: bold; /* Make dropdown text bold */
  width: auto; /* Make the dropdown width adaptable */
  min-width: 150px; /* Optional: Set a minimum width */
  margin-top: 20px; /* Reduced from 20px */
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #FFE4C7; /* Light beige background color */
  padding: 5px 10px;
  border-radius: 15px;
  height: 40px;
}

.search-bar input {
  border: none;
  background-color: #FFE4C7; /* Light beige background color */
  margin-left: 5px;
  font-size: 1em;
}

.search-icon {
  color: #000; /* Color of the spyglass icon */
}

.header-controls {
  display: flex;
  align-items: center;
}

.sort-dropdown-container {
  display: inline-flex;
  align-items: center;
  background-color: #FFE4C7; /* Light beige background color */
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em; /* Smaller font size */
  margin-left: 10px; /* Add some space between search bar and sort dropdown */
  height: 40px;
  width: auto; /* Allow the container to adjust its width */
}

.sort-dropdown-container span {
  margin-right: 5px; /* Add some space between "Sort by" and the dropdown */
  white-space: nowrap; /* Prevent "Sort by" from wrapping */
}

.sort-dropdown-container select {
  border: none;
  background-color: transparent;
  font-size: 1em;
  font-weight: bold; /* Make dropdown text bold */
  width: auto; /* Make the dropdown width adaptable */
  min-width: 100px; /* Optional: Set a minimum width */
  -webkit-appearance: none; /* Remove default dropdown arrow for webkit browsers */
  -moz-appearance: none; /* Remove default dropdown arrow for Mozilla browsers */
  appearance: none; /* Remove default dropdown arrow */

  margin-top: 19px;
}

/* Optional: Adding a custom arrow to the dropdown */
.sort-dropdown-container::after {
  content: '▼'; /* Custom arrow */
  font-size: 0.6em;
  margin-left: 10px;
  pointer-events: none; /* Prevents the arrow from interfering with dropdown functionality */
  align-self: center; /* Align the arrow to the center of the container */
}

.starred-candidates-page .download-button {
  display: flex; /* Show the button */
}

.download-button {
  display: none; /* Hide the button by default */
  background-color: #FFE4C7; /* Match the background color of the dropdown */
  border: none;
  padding: 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Make the button circular */
  margin-left: 5px;
}

.download-button:hover {
  background-color: #d2bca5; /* Slightly darker on hover */
}

.stats-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  background-color: #fff8e3; /* Combined box background color */
  padding: 9px; /* Padding for the combined box */
  border-radius: 20px; /* Rounded corners for the combined box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for the combined box */
}

.stat-card {
  background-color: transparent; /* Make individual stat cards transparent */
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex: 1; /* Allow stat cards to grow equally */
  margin: 0 10px; /* Add some space between stat cards */
}

.stat-card:first-child {
  margin-left: 0; /* Remove left margin for the first stat card */
}

.stat-card:last-child {
  margin-right: 0; /* Remove right margin for the last stat card */
}

.stat-icon {
  font-size: 24px;
  margin-right: 10px;
}

.stat-info {
  display: flex;
  flex-direction: column;
}

.stat-info p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}

.stat-info h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold; /* Make h3 bold */
}

.circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e0f7fa; /* Light blue background */
  /* border: 2px solid #0288d1; */
  color: #0288d1; /* Blue icon color */
}

.green-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #e0f7fa; /* Light green background */
  /* border: 2px solid #4caf50; */
  color: #4caf50; /* Green icon color */
}

.yellow-circle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ffe0b2; /* Light orange background */
  /* border: 2px solid #ff9800; */
  color: #ff9800; /* Orange icon color */
}

.candidates-section {
  background-color: #fff8e3; /* Elements that stand out */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Make the section scrollable */
  height: calc(61vh); /* Adjust height to fit within the viewport */
}

.candidates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.candidates-header h2 {
  margin: 0;
}

.candidates-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  font-weight: 500; /* Make the text semi-bold */

}

.candidates-table {
  width: 100%;
  border-collapse: collapse;
}

.candidates-table th, .candidates-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.candidates-table thead th {
  background: transparent; /* Remove white box around the table header */
  color: gray; /* Header text color */
}

.candidates-table td {
  color: black; /* Row text color */
}

.candidates-table tr {
  border-bottom: 1px solid gray; /* Gray lines below each row */
}

.candidates-table tr:hover {
  background-color: #f0f0f0; /* Change to a darker color on hover */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.candidates-table tr.selected-row {
  background-color: #FF6B19; /* Highlight selected row in orange */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #fff8e3; /* Elements that stand out */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.popup-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  margin: 5px;
}

.popup-button:hover {
  background-color: #0056b3;
}

.candidate-info-slide-in {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.candidate-info-slide-in.active {
  transform: translateX(0);
}

.status-label {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.status-label.completed {
  color: darkgreen;
  background-color: lightgreen;
  border: 1px solid darkgreen;
}

.status-label.in-progress {
  color: brown;
  background-color: gold;
  border: 1px solid brown;
}

.status-label.not-started {
  color: darkred;
  background-color: lightcoral;
  border: 1px solid darkred;
}

.copy-link-button, .generate-link-button {
  width: auto;
  height: 40px;
  margin-top: 10px;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff8e3; /* Match the color of the position dropdown section */
  color: #ff6b19; /* Adjust text color if needed */
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  display: flex; /* Ensure flexbox layout */
  justify-content: center; /* Center the content */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}

.link-in-button {
  color: black; /* Adjust text color if needed */
  font-weight: normal;
}

.copy-link-button:hover, .generate-link-button:hover {
  background-color: #e0d9c8;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Enhance drop shadow on hover */
}

.generate-link-button .link-icon {
  margin-right: 8px; /* Ensure the icon is to the left of the text */
}

.copy-link-button .copy-icon {
  /* margin-right: 8px; */
  margin-left: 8px;
  color: #000;
}

.refresh-button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  font-size: 1rem;
  color: inherit;
}
.refresh-button:hover {
  color: #007bff; /* Optional: Change color on hover */
}
