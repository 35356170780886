@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* hello */

/* General styles for nav - assuming it should be flex on wider screens by default */
/* Base styles for nav links: always visible on wide screens */
nav ul {
  display: flex;  /* Show the navigation links */
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  
}

.hamburger {
  margin-top: 5px;
  margin-left:auto;
  max-height: 100px;
  height: auto;
  display: none;  /* Hide the hamburger menu by default */
}

@keyframes fadeIn {
  from { opacity: 0; height: 0px; }
  to { opacity: 1; height: 150px; }
}

@keyframes fadeOut {
  from { opacity: 1; height: 150px; }
  to { opacity: 0; height: 0px; }
}


/* Hide nav links and show hamburger menu on smaller screens */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
    height: auto;  /* Ensures the hamburger menu is visible on narrow screens */
  }

  /* .video-container {
    filter:hue-rotate(330deg); /* Initial state */
  /*} */
  
  nav ul {  
    display: none;
    height: 0;
    overflow: hidden;
    flex-direction: column;
    position: absolute;
    background-color: rgba(64, 193, 193, 0.9);
    top: 105px;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    transition: none;

  
  }

  

  nav ul li a {
    color: #eee7e2;
    text-decoration: none;
    font-weight: normal;
    font-size: 30px;
    padding-right: 23px;
    padding-top: 20px;
    display: inline-block;
  
  }

  
  
  nav ul.show {
    display: block;
    height: 145px;
    overflow: hidden;
  }

  nav ul.hide {
    display: none;
    height: 0;
    overflow: hidden;
  }

  nav ul.animate-show {
    animation: fadeIn 0.5s forwards;  /* Apply animation only when class is added */
  }

  nav ul.animate-hide {
    display: flex;
    animation: fadeOut 0.5s;  /* Apply animation only when class is added */
  }


}



body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Poppins', Arial, sans-serif;

}
/*
.end-cursor:after {
  content: '|';
  color: white; /* Ensures the cursor is visible, adjust color as needed
  animation: blink 1s step-start infinite;
}
*/
  .end-cursor:after {
    content: '|';
    color: #d1d5db; /* Equivalent to text-secondary-300 */
  }

  .end-cursor.blinking:after {
    transition: background-color 0.5s ease;
    animation: blink 1s step-start infinite;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

.App {
  text-align: center;
}



@keyframes fadeInError {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.error-popup {
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  background-color: rgba(221, 60, 60, 0.553);
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.2);
  z-index: 1050;
  text-align: center;
  opacity: 1;
  transition: opacity 0.5s; 
}

.error-popup.show {
  animation: fadeInError 5s linear 1 forwards;

  
}

/* Style for the card container */
.card {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  margin-left: 20px;
  z-index: 1;
}



/* Style for the card image */
.card-img-top {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Keeps the aspect ratio, might crop the image */
}

/* Style for the card title */
.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0.5rem 0.5rem;
}

/* Style for the card text */
.card-text {
  color: #666;
  margin: 0.5rem 0.5rem;
}

/* Style for the card button */
.card .btn {
  display: block;
  width: calc(100% - 2rem); /* Subtracting the left and right margin */
  margin: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  text-decoration: none; /* In case you use an <a> tag */
  transition: background-color 0.2s ease-in-out;
}


.man-inter {

  top: 14rem;
  height: 10rem;
  position: relative;
  right: 20px;
  width: 24rem;
    

}

.stat-style {

    top: 14rem;
    height: 29rem;
    position: relative;
    right: 20px;
    width: 24rem;
}

.interviewtext {
  font-weight: bold;
  position: relative; /* Positioned absolutely within .image-container */
  top: 14rem; /* Align to the bottom of .image-container */
  right: 33.5rem; /* Align to the left of .image-container */
  color: black; /* Set text color */
  padding: 10px; /* Some padding around the text */
  /* Optional: Adds semi-transparent background to the text for readability */
}

.section-headerprac {
  top: 117rem; /* Align to the bottom of .image-container */
  right: 33.5rem; /* Align to the left of .image-container */
  color: black; /* Set text color */
  font-size: 24px;
  color: #333;
}
 
/* .fade-in-target {
  opacity: 0;
  transition: opacity 1s ease;
}

.fade-in-target.visible {
  opacity: 1;
} */


.section-headerprac {
  top: 14rem; /* Align to the bottom of .image-container */
  right: 33.5rem; /* Align to the left of .image-container */
  color: black; /* Set text color */
  font-size: 24px;
  color: #333;
}

.section-headerinter {
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.custom-cursor-hover:hover {
  cursor: url('./go.png') 16 16, auto; /* Path to your 'GO' cursor image, 16 16 is the image hotspot coordinates */
  transition: cursor 2s ease;
  transition: transform 1s ease/* Smooth transition for the cursor change */
}

.image-hover-effect {
  transition: transform 3s ease, box-shadow 3s ease;
  display: inline-block; /* or block depending on layout */
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.0));
}

.image-hover-effect:hover {
  transform: scale(1.05); /* Slightly increase the size of the image */
  filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
}


.stat-text {
  font-weight: bold;
  position: absolute; /* Positioned absolutely within .image-container */
  top: 14rem; /* Align to the bottom of .image-container */
  right: 5.7rem; /* Align to the left of .image-container */
  color: black; /* Set text color */
  padding: 10px; /* Some padding around the text */
  /* Optional: Adds semi-transparent background to the text for readability */
}



@keyframes slideFadeInOut {
    0% {
        opacity: 0;
        transform: translateY(-2.5rem)
    }

    50% {
        opacity: 1;
        transform: translateY(0)
    }

    to {
        opacity: 0;
        transform: translateY(2.5rem)
    }
}

@keyframes focusVideo {
  from {
    filter: brightness(50%) sepia(0) saturate(0) hue-rotate(25deg) blur(10px);
  }
  to {
    filter: brightness(70%) sepia(0) saturate(0) hue-rotate(25deg) blur(0px);
  }
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  position: relative; /* Ensures that child positioning is relative to this element */
  z-index: 70; /* Keeps the menu bar above other content */
  width: 100%; /* Ensure full width */
  background: rgba(0, 0, 0, 0.4);/* Set background color to black */
  backdrop-filter: none; /* Remove the blurring effect */
  -webkit-backdrop-filter: none; /* Remove the blurring effect */
   box-shadow: 0 8px 10px 0 rgba(0, 0, 0, .3); 
  /*border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;*/
  transition-timing-function: linear;
  transition: top 1s;
  position: fixed;
  top: 0px;
  z-index: 70; /* Highest to ensure it overlays everything */
  height: 70px; /* Scale down from 100px to 70px */
}
/* Hide the menu initially on small screens */

@media (max-width: 768px) {
  .menu-bar {
  
    /* Adding our borders*/
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      
    }
}

.menu-bar.hide {
  top: -105px;
}

.menu-bar.show {
  transform: translateY(0);
}

.logo {
  padding: 12px;
  width: 130px; /* Scale down from 300px to 210px */
  height: auto; /* Maintain aspect ratio */
}

nav ul {
  list-style: none;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

@media (min-width: 768px) {

nav ul li a {
  color: #eee7e2;
  text-decoration: none;
  font-weight: normal;
  font-size: 21px; /* Scale down from 30px to 21px */
  padding-right: 16px; /* Scale down from 23px to 16px */
  padding-top: 21px; /* Scale down from 30px to 21px */
  display: inline-block;

}
}

/* Display the hamburger icon on smaller screens */
.dud {
  color:rgba(243, 105, 14, 0.9);
  max-width: 80%;
  position: relative;
  font-size: large;
  font-weight: 900;
}

.content {
  /*grid stuff */

  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: 800px 800px 1100px 1000px; /* Add this line to define two rows */
  grid-template-areas: 
    "top"
    "middle"
    "bottom"; /* Define the layout areas */

  
  /*styling stuff */
  background: #eee7e2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: auto;
  min-height: calc(100vh);
  width: 100vw;
  z-index: 0;
  
  
}



/* Assuming .content is the parent, this increases specificity */




.top-grid-element {
  grid-area: top; /* Assign to the top area */
  /* Decrease the height of the white part */
  background: linear-gradient(to bottom, black, rgb(0, 0, 53)); /* Change to gradient from navy blue to black */
  .contentBody {
    color: white;
    font-size: 1.2rem;
    font-weight: bold; /* Changed from normal to bold */
    bottom: 40%;
    margin-right: 40%;
    margin-left: 5%;
    margin-top: 2%;
    z-index: 1;
    transform: translateY(30px);
    opacity: 0;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .contentBody.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
}

.middle-grid-element {
  grid-area: middle; /* Assign to the middle area */
  background: linear-gradient(to bottom, rgb(0, 0, 53), black);
  .contentHeader {
    color: rgb(214, 112, 227);
  
  }
 
  .contentBody {
    color: white;
    font-size: 1.2rem;
    font-weight: bold; /* Changed from normal to bold */
    bottom: 40%;
    margin-right: 40%;
    margin-left: 5%;
    margin-top: 2%;
    z-index: 1;
    transform: translateY(30px);
    opacity: 0;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .contentBody.visible {
    opacity: 1;
    transform: translateY(0);
  }
  
}

.bottom-grid-element {
  grid-area: bottom; /* Assign to the bottom area */
  background: linear-gradient(to bottom, black, rgb(0, 0, 53));
  .contentHeader {
    background: linear-gradient(to bottom, lightpink, lightcoral); /* Faint from light pink to light red */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 900; /* Make the font more bulky */
  }
  .contentBody {
    color: white;
    font-size: 1.2rem;
    font-weight: bold; /* Changed from normal to bold */
    bottom: 40%;
    margin-right: 40%;
    margin-left: 5%;
    margin-top: 2%;
    z-index: 1;
    transform: translateY(30px);
    opacity: 0;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .contentBody.visible {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes woosh {
  0% {
    
    opacity: 0;
  }
  30% {
    opacity: 1;
    
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.infopic {
  width:58.71%; /* you can use % */
  height: auto;
  justify-self: end;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.infopic.visible {
  opacity: 1;
  transform: translateY(0);
}

.info-text {
  font-size: x-large;
  position:absolute;
  color: #eee7e2;
  margin-left: 5%;
  margin-right:30%;
  margin-top: 6.5%;
  max-width:25%;
  font-weight:600;
  transform: translateY(30px);
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.info-text.visible {
  opacity: 1;
  transform: translateY(0);
}









@media (max-width: 900px) {
  .content {
    grid-template-columns: 1fr; /* Stacks the grid items vertically under each other */
  }

  

  .info-text {
    margin-top: 12%; 
    font-size: large;
  }
}

/* .grid-container--fill {
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.grid-container--fit {
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
} */


.item {
  z-index: 999999;
  /* example styling for items if needed */
}



.top-right-cell {
  display: grid;
}

.bottom-right-cell {
  display: grid;
}

.contentHeader {
  margin-left: 5%;
  margin-right: 10%;
  font-size: 3rem;
  margin-top: 12%;
  font-weight:900;
  line-height: 1;
  width: 60%;
  transform: translateY(30px);
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  color: rgb(166, 166, 241); /* Change the font color to blue */
}


.contentHeader.visible {
  opacity: 1;
  transform: translateY(0);
}

.contentBody {
  font-size: 1rem;
  font-weight: normal;
  bottom: 40%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 5%;
  z-index: 1;
  transform: translateY(30px);
  opacity: 0;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.contentBody.visible {
  opacity: 1;
  transform: translateY(0);
}

.contentImage {
  display: block;
  margin: 20px auto; /* Center the image horizontally */
  max-width: 100%; /* Ensure the image is responsive */
  height: auto; /* Maintain aspect ratio */
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.contentImage.visible {
  opacity: 1;
  transform: translateY(0);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #eee7e2;
}

.sign-in-message {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  margin-left: 5px; /* Adjust as needed */
  border-radius: 2px;
}

.dropbtn {
  background-color: transparent;
  color: #eee7e2;
  font-size: 21px; /* Scale down from 30px to 21px */
  border: none;
  cursor: pointer;
  padding-right: 16px; /* Scale down from 23px to 16px */
  padding-top: 21px; /* Scale down from 30px to 21px */
  display: inline-block;
  position: relative;
}

/* Down arrow */
.arrow-down {
  font-size: 25px;
  margin-left: 0px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(64, 193, 193, 0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #eee7e2;
  padding: 12px 16px;
  font-size: 20px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgba(64, 193, 193, 0.3);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video-thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px; /* Adjust this value to move the video slightly to the left */
  border: 2px solid #ccc; /* Box for the video thumbnail */
  padding: 10px;
  background-color: #f9f9f9;
}

.embedded-video {
  width: 100%;
  max-width: 600px; /* Adjust the max-width as needed */
  height: auto;
}

#youtube-video {
  width: 100%;
  height: 315px;
}


@media (max-width: 768px) {
  .hamburger {
    display: flex;
    height: auto;
  }

  nav ul.show {
    display: block;
    height: auto;
    overflow: hidden;
  }

  nav ul.hide {
    display: none;
    height: 0;
    overflow: hidden;
  }

  .dropdown .dropdown-content {
    display: none !important; /* Hide dropdown content on mobile */
  }
}

/* Add this to your CSS file (e.g., App.css) */
.button-link {
  position: absolute;
  top: calc(50% - 80px); /* Move down 20px from the previous position */
  left: calc(50% - 5px); /* Move left 5px from the previous position */
  transform: translate(-50%, -50%);
  z-index: 2;
}

.button-image {
  width: 300px; /* 3 times the original size (100px * 3) */
  height: auto;
}