.sidebar {
  width: 300px;
  background-color: #fff8e3; /* Sidebar background color */
  display: flex;
  flex-direction: column;
  padding-left: 20px; /* Add margin on the left */
  margin-top: 80px; /* Maintain the 110px downshift */
  border-radius: 15px; /* Rounded corners for the sidebar */
  margin-left: 10px;
  margin-bottom: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
  
}

.sidebar-header {
  padding: 20px;
  color: black;
  text-align: left; /* Align text to the left */
  font-size: 35px; /* Increase font size */
  margin-top: 10px; /* Add margin to ensure it's not cut off */
  font-weight: 500; /* Make the text semi-bold */

}

.sidebar-nav {
  flex-grow: 1;
}

.sidebar-nav table {
  width: 100%;
  border-collapse: collapse;
}

.sidebar-nav tr {
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% - 10px); /* Reduce width to add spacing on both sides */
  box-sizing: border-box;
  color: gray;
  border-radius: 10px; /* Rounded corners for rows */
}

.sidebar-nav tr.selected {
  background-color: #FF6B19; /* New selected color */
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Drop shadow only for selected row */
}

.sidebar-nav td {
  padding: 5px;
}

.sidebar-nav td:first-child {
  flex: 0 0 auto; /* Prevent the icon from growing or shrinking */
  margin-left: 1px;
}

.sidebar-nav td:nth-child(2) {
  flex: 1; /* Allow the text to take up remaining space */
  margin-left: 1px; /* Add space between icon and text */
  margin-right: 20px;
}

.sidebar-nav td:last-child::after {
  margin-left: auto; /* Push the arrow to the far right */
  margin-right: 1px; /* Add space between arrow and right edge */
}

.arrow-icon::after {
  display: flex;
  align-items: center;
}

.sidebar-footer {
  padding: 10px 20px;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
}

.sidebar-footer:hover {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.footer-icon {
  flex: 0 0 20px; /* Keep consistent icon size */
  margin-right: 10px;
}

.user-details {
  flex: 1; /* Allow text to grow */
  text-align: left;
  display: flex;
  flex-direction: column;
}

.user-name {
  font-size: 14px;
  color: black;
  margin: 0;
}

.user-company {
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin: 0;
}

.footer-arrow {
  flex: 0 0 auto; /* Align arrow to the far right */
  margin-left: auto;
}

.resume-screener {
  font-size: 0.89rem; 
  display: inline;
}

.beta-badge {
  background-color: limegreen; /* Light orange color */
  color: white;
  border-radius: 12px;
  padding: 2px 6px;
  font-size: 10px;
  margin-left: 5px;
  font-weight: bold;
}