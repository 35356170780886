/* src/PositionsSection.css */

.positions-container {
  padding: 20px;
  margin: 20px;
  background-color: transparent; /* No background */
}

.create-interview-container {
  background-color: #fff8e3; /* Light beige background color for the container */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 15px 25px; /* Decreased padding to reduce height */
  text-align: left; /* Align text to the left */
  margin-bottom: 20px; /* Space below the container */
  max-width: 100%; /* Maintain full width */
  margin-top: -35px; /* Reduce top margin to shift it up */
}

.create-interview h2 {
  margin: 0;
  font-size: 24px; /* Maintain the same font size */
  font-weight: 600;
  color: #333; /* Darker text color */
}

.create-interview p {
  margin-bottom: 15px; /* Reduce bottom margin for shorter height */
  color: #6c757d; /* Text color */
  font-size: 14px; /* Slightly smaller text */
}

.create-new-interview {
  padding: 5px 20px; /* Keep button size the same */
  border: none;
  border-radius: 50px;
  background-color: #FF6B19; /* Orange button background color */
  color: white;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added drop shadow */
}

.create-new-interview:hover {
  background-color: #e55a17;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added drop shadow */
}

.positions-list-container {
  background-color: #fff8e3; /* Light beige background color for the container */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  padding: 20px;
  margin-top: 20px;

  max-height: 68vh; /* Constrain height to 60% of viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.positions-list {
  display: flex;
  flex-direction: column;
}

.positions-list h2 {
  margin: 0;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
}

.position-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #ececec;
}

.position-details {
  display: flex;
  flex-direction: column;
}

.position-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #FF6B19; /* Orange text color */
}

.position-meta {
  margin-top: 5px;
  color: #6c757d; /* Text color */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
  font-size: 12px;
}

.position-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.edit-button {
  background-color: #FFE4C7;
  border: 3px;
  border-color: black;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 5px;  
  margin-left: 5px;

}

.edit-button:hover {
  background-color: #dbc4ab;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added drop shadow */
}

.no-positions {
  font-size: 16px;
  color: gray;
  margin-top: 20px;
}
/* Hide the trash icon by default */
.trash-icon {
  font-size: 16px;
  margin-top: 6px;
  color: #FF6B19;
  margin-left: 10px;
  cursor: pointer;
  visibility: hidden; /* Hide icon by default */
}
/* Show the trash icon when the row is hovered */
.position-item:hover .trash-icon {
  visibility: visible; /* Show only on hover */
}
.trash-icon:hover {
  color: #e55a17; /* Change the color when hovering over the trash icon */
}
