.cancel-membership-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff8e3;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .cancel-membership-page h1 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
  }
  
  .cancel-membership-page p {
    font-size: 18px;
    color: #666;
    margin-top: 10px;
  }
  