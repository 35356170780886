.rounded-container {
  display: grid;
  grid-template-rows: auto 1fr; /* auto for the title, 1fr for the chart containers */
  gap: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.graph-container {
  flex: 1;


}

.graph-title {
  position: relative;
  margin-left: 45%;
  font-size: 24px;
}

.practice-title {
  
  margin-left: 0%;
  font-size: 24px;
}

.line-chart-container {
  flex: 1; 
  margin-left: 0px;
  width: 100%;
  min-height: 100%;
}
.line-chart {
  width:100%;
  height: 500;
}
.pie-chart-container {
  flex: 1; /* Occupy remaining space */
  display: flex;
  justify-content: center; /* Center the pie chart horizontally */
  align-items: center; /* Center the pie chart vertically */
}



.wide-rectangle {
  background-color: white;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 30% 70%; /* Adjust according to your design */
  gap: 20px;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;  
  margin-top: 10px;

}

.data-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Adjust minmax as needed */
  gap: 10px; /* Space between items */
  justify-content: center; /* Center items in the container */
  align-items: center;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.circle {
  /* Keep your existing styles, and you might define a fixed size for the circles here */
  width: 60px; /* Example size */
  height: 60px; /* Example size */
  border-radius: 50%; /* Makes it round */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Assuming you want the text color to be white */
  font-weight: bold; /* If you want the number to be bold */
}

.key-font {

  font-size: 100%;

}

@media (max-width: 768px) { /* Adjust the max-width as needed for your design */
  .wide-rectangle {
    grid-template-columns: 1fr; /* One column takes up the full width */
    grid-template-rows: auto auto; /* Two rows, each size depends on its content */
  }

  /* Assuming "Practice Now" and the button are in a container with a class like .practice-now-container */
  .practice-now-container {/* Moves this container to the first row */
    grid-row: 1 / 2;
    align-items: center;
    margin-left: 30%;
  }

  .data-container {
    grid-row: 2; /* Moves the circles to the second row */
  }

  .graph-title {
    position: relative;
    margin-left:35%;
    font-size: 24px;
  }
}

.practice-page {
  background-color: #FFE4C7;
  margin-top: 65px;
  height: 100vh; /* Occupy full viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
  z-index: 2;
}

body {
  background-color: black;

}
