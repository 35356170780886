.candidate-info {
  position: fixed;
  right: 0;
  top: 0;
  width: 40%;
  height: 100%; 
  background-color: #fff8e3;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

/* Slide the sidebar in when .show is added */
.candidate-info.show {
  transform: translateX(0);
}

.candidate-info-scroll {
  position: relative; /* so absolutely positioned children use this as reference */
  overflow-y: auto;
  flex: 1;           /* fill the vertical space */
  padding: 20px;     /* your desired spacing */
}

.candidate-info-scroll {
  position: relative;
  overflow-y: auto;
  flex: 1;
  padding: 20px;
}

.video-popup {
  position: relative; /* For internal positioning */
  z-index: 9999; /* Ensure it's above the overlay */
  max-width: 90%;
  max-height: 90%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.video-sticky-wrapper {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.video-popup-content {
  position: relative; /* Optional, for further styling */
  padding: 20px;
}

.video-overlay {
  position: fixed; /* Covers the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Black with 70% transparency */
  z-index: 9998; /* Ensure it's above everything except .video-popup */
  display: flex;
  justify-content: center;
  align-items: center;
}

.candidate-info-header {
  text-align: center;
  font-size: 18px; /* Double the font size */
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.skill-box {
  background-color: lightgreen;
  color: darkgreen;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 600; /* Made the text slightly thicker */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid darkgreen;
}

.overall-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.overall-circle {
  background-color: #FFE4C7; /* New background color */
  color: #000; /* Black text */
  font-weight: bold; /* Bold text */
  width: 120px; /* Increase size */
  height: 120px; /* Increase size */
  font-size: 1.5em; /* Adjust text size */
}

.candidate-info-traits {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap; /* Prevent wrapping to a new line */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
  margin-bottom: 20px;
}

.trait-item {
  text-align: center;
  margin: 10px;
  flex: 0 0 auto; /* Prevent flex items from shrinking */
  font-size: 0.8em; /* Smaller text size */
}

.score-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #87CEEB; /* Default color for trait scores, will be overridden inline */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px; /* Add space between circle and trait name */
}

.candidate-info-feedback {
  margin-bottom: 20px;
}

.candidate-info-transcript {
  margin-bottom: 20px;
}

.transcript-item {
  margin-bottom: 10px;
}

.horizontal-circles {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.circle-with-word {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc; /* Default color, will be overridden by inline style */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6em; /* Smaller text size */
}

.trait-word {
  margin-top: 5px;
  font-size: 0.7em; /* Smaller text size */
  color: #333;
}

.feedback-content {
  margin-top: 10px;
}

.feedback-content.hidden {
  display: none;
}

.feedback-content.visible {
  display: block;
}

.chat-bubbles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.bubbletemplate {
  background-color: white;
  padding: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 60%;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.bubbletemplate.ai {
  align-self: flex-start;
  background-color: #e0e0e0;
  border-radius: 0 10px 10px 10px;
}

.bubbletemplate.human {
  align-self: flex-end;
  background-color: #FF6B19;
  color: white;
  border-radius: 10px 0 10px 10px;
  margin-left: calc(40% - 1px);
}

.play-button-speech {
  background-color: #FF6B19;
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px;
}

.play-button:hover {
  background-color: #e55a17;
}

.copy-link-button-candidate {
  background-color: #FF6B19;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9em;
  width: auto;
  height: 30px;
  margin-top: 10px;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 12px;
  display: flex; /* Ensure flexbox layout */
  justify-content: center; /* Center the content */
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add drop shadow */
}

.copy-link-button-candidate:hover {
  background-color: #e55a17;
}