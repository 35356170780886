/* businessregister.css */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.register-page-container {
    display: flex;
    justify-content: space-between; /* Distribute space between the items */
    align-items: center;
    height: 100vh;
    background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
    font-family: 'Open Sans', sans-serif; /* Update the font */
    overflow: hidden;
}

.practice-warning {
    color: red;
    text-align: center;
    margin-top: 300px;
    position: fixed;
}

.register-main-container {
    display: flex;
    justify-content: space-between; /* Distribute space between the items */
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Set a max width to keep it centered */
    height: 100%;
    padding: 0 20px;
    margin-top: 5%; /* Shift the form container down */
}

.register-form-container {
    flex: 1;
    max-width: 400px; /* Set a max-width for the form container */
    color: black;
    text-align: left; /* Align text to the left */
    margin-left: 15%; /* Move the form to the left */
}

.register-form-container h3 {
    font-size: 32px;
    margin-bottom: 12px; /* Increase space below the title */
    font-weight: 600; /* Update the font weight */
    text-align: left; /* Left align the text */
}

.register-form-container p {
    font-size: 18px;
    margin-bottom: 18px; /* Increase space below the subtitle */
    font-weight: 400; /* Update the font weight */
    text-align: left; /* Left align the text */
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Increase gap between form groups */
}

.form-group {
    margin-bottom: 8px; /* Increase spacing between groups */
}

.form-group label {
    font-size: 12px; /* Keep the label font size smaller */
    font-weight: 600; /* Update the font weight */
    display: block;
    margin-bottom: 5px; /* Increase spacing below the label */
}

.form-input {
    width: 100%;
    padding: 8px; /* Increase padding for input boxes */
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 4px; /* Increase spacing above the input */
    background: rgba(255, 255, 255, 0.8); /* Add a slight background to text inputs */
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 8px; /* Increase spacing */
}

.register-button {
    background-color: #000000; /* Black background */
    color: white; /* White text */
    border: none;
    border-radius: 10px; /* Rounded corners */
    padding: 12px 20px; /* Increase padding */
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 12px; /* Increase space above the button */
    font-size: 18px; /* Adjust font size */
    font-weight: 600; /* Make text bold */
    width: 100%; /* Make button wide */
    text-align: center; /* Center the text */
}

.register-button:hover {
    background-color: #333333; /* Darken slightly on hover */
}

.register-button.registering {
    background-color: #cccccc;
    cursor: not-allowed;
}

.signup-text {
    margin-top: 12px; /* Increase spacing */
    font-size: 16px;
    font-weight: 600; /* Make text bold */
}


.register-image-container {

    display: none;
    position: absolute;
    right: 10vw;

}

.register-image {
    height: 100%; /* Ensure it takes the full height */
    width: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: to match the design style */
}
@media (min-width: 1024px) {
    .register-image-container {
        display: block; /* Show the container */
        height: 80%; /* Ensure it scales properly */
        margin-top: 8%;
        margin-bottom: 8%;

    }

    .register-image {
        max-height: 100%; /* Prevent overflow on large screens */
        max-width: 100%; /* Prevent overflow on large screens */
    }
}
