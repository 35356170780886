.api-documentation-page {
  background-color: #f5f5f5; 
  min-height: 100vh;
  box-sizing: border-box;
}

  .api-documentation-container {
    margin-top: 75px; /* Shift content down by 20px */
    padding: 20px; /* Optional padding for spacing */
    box-sizing: border-box; /* Ensure padding does not affect layout */
  }
  
  .server-copy-container {
    margin-bottom: 20px; /* Space between server section and Swagger UI */
    display: flex;
    align-items: center;
    gap: 10px; /* Space between elements */
  }
  
  .server-label {
    font-weight: bold;
  }
  
  .server-input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .copy-button {
    padding: 8px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .copy-button:hover {
    background-color: #0056b3;
  }
  