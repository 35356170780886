/* business-signin.css */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

.signin-page-container {
    display: flex;
    justify-content: space-between; /* Distribute space between the items */
    align-items: center;
    height: 100vh;
    background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
    font-family: 'Open Sans', sans-serif; /* Update the font */
}

.practice-warning {
    color: red;
    text-align: center;
    margin-top: 300px;
    position: fixed;
}

.signin-main-container {
    display: flex;
    justify-content: space-between; /* Distribute space between the items */
    align-items: center;
    width: 100%;
    max-width: 1200px; /* Set a max width to keep it centered */
    height: 100%;
    padding: 0 20px;
}

.signin-form-container {
    flex: 1;
    max-width: 400px; /* Set a max-width for the form container */
    color: white;
    text-align: left; /* Align text to the left */
    margin-left: 15%; /* Move the form to the left */
}

.signin-form-container h2 {
    font-size: 32px;
    margin-bottom: 5px; /* Reduce space below the title */
    font-weight: 600; /* Update the font weight */
}

.signin-form-container p {
    font-size: 18px;
    margin-bottom: 10px; /* Reduce space below the subtitle */
    font-weight: 400; /* Update the font weight */
}

.signin-form {
    display: flex;
    flex-direction: column;
    gap: 0; /* Remove gap between form groups */
}

.form-group {
    margin-bottom: 5px; /* Reduce space between form groups */
}

.form-group label {
    font-weight: 600; /* Update the font weight */
    display: block;
    color: black;
    margin-bottom: 2px; /* Reduce space below the label */
}

.form-input {
    width: 100%;
    padding: 6px; /* Reduce padding for input boxes */
    border: 1px solid #ccc;
    color: black;
    border-radius: 5px;
    margin-top: 2px; /* Reduce space above the input */
    background: rgba(255, 255, 255, 0.8); /* Add a slight background to text inputs */
}

.password-container {
    position: relative;
}

.toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
}

.forgot-password-link {
    display: block;
    margin-top: 5px;
    text-align: right;
    color: #007bff;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 5px; /* Reduce space below the error message */
}

.signin-button {
    background-color: #000000; /* Change to match the image */
    color: white;
    font-weight: 600; /* Make button text bold */
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px; /* Reduce space above the button */
    width: 100%; /* Make button wide */
    text-align: center; /* Center the text */
}

.signin-button:hover {
    background-color: #333333; /* Darken slightly on hover */
}

.signin-button.signing-in {
    background-color: #cccccc;
    cursor: not-allowed;
}

.signup-text {
    margin-top: 10px; /* Reduce space above the signup text */
    font-size: 16px;
    font-weight: 600; /* Make text bold */
}


/* General styling for the image */
.signin-image {
    height: 100%; /* Ensure it takes the full height */
    width: auto; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: to match the design style */
}

/* Styling for the page container */
.signin-page-container-payment-confirmed {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%; /* Make sure it takes the full width */
    background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
    font-family: 'Open Sans', sans-serif;
    overflow: hidden; /* Prevent any overflow issues */
}

/* Hide the image by default */
.signin-image-container {
    display: none;
    position: absolute;
    right: 10vw;
}

/* Show the image on full screens (1024px or wider) */
@media (min-width: 1024px) {
    .signin-image-container {
        display: block; /* Show the container */
        height: 80%; /* Ensure it scales properly */
        margin-top: 8%;
    }

    .signin-image {
        max-height: 100%; /* Prevent overflow on large screens */
        max-width: 100%; /* Prevent overflow on large screens */
    }
}


.content-container-payment-confirmed {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.congrats-message-payment-confirmed {
    font-size: 2em;
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
}

.continue-button-payment-confirmed {
    background-color: #d63384;
    color: white;
    border: none;
    border-radius: 25px; /* Oval shape */
    padding: 15px 30px; /* Adjust padding to emphasize oval look */
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    text-align: center;
}

.continue-button-payment-confirmed:hover {
    background-color: #c2185b;
}

.shake {
    animation: shake-animation 0.5s ease;
}

@keyframes shake-animation {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
}
