.sign-in-selection-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
  }
  
  .sign-in-selection-content {
    margin-top: 200px;
    margin-bottom: auto;
    color: white;

  }
  
  .sign-in-button-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .individual-sign-in-button, .business-sign-in-button {
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .individual-sign-in-button {
    background-color: white;
    color: green;
    border: 2px solid green;
  }
  
  .business-sign-in-button {
    background-color: green;
    color: white;
    border: 2px solid green;
  }
  