.outreach-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #FFE4C7;
  padding: 20px;
  height: 80vh; /* Ensure it fits the screen height */
  box-sizing: border-box;
}

.upload-popup-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.select-container {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.select-container select {
  padding: 10px;
  border: 1px solid #F9F3E4;
  width: 100%;
  max-width: 300px;
  background-color: #F9F3E4;
  border-radius: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: black;
 }

.button-container {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: flex-start;
}

.outreach-button {
  padding: 10px 20px;
  border: none;
  background-color: #FF6B19;
  color: white;
  border-radius: 5px;
  cursor: pointer;
 }

.outreach-button:hover {
  background-color: #e65a00;
}

.email-sent-message {
  margin-top: 20px;
 }

.content-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
}

.edit-template-container {
  width: 37.5%;
  background-color: #F9F3E4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   height: 100%; /* Decreased height by 20% */
}

.add-candidates-container {
  width: 60%;
  background-color: #F9F3E4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   height: 100%; /* Decreased height by 20% */
  position: relative;
}

.edit-template-header,
.add-candidates-header {
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: bold;
 }

.edit-template-textarea {
  width: 100%;
  height: 300px; /* Decreased height proportionally */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  resize: vertical;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
 }

.sub-headers {
  display: flex;
  justify-content: flex-start;
  font-size: 0.9em;
  margin-bottom: 10px;
  font-weight: bold;
 }

.sub-headers span:first-child {
  margin-right: auto;
}

.sub-headers span:nth-child(2) {
  margin-left: auto;
  margin-right: 43%;
}

.candidate-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.candidate-row input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
 }

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}
/* src/OutreachSection.css */
.file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #FFE4C7;
  color: black;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  height: 25px;
  font-size: 0.9em; /* Match the font size of the span */
  line-height: 5px; /* Center the text vertically */
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Add a small drop shadow */
}

.custom-file-label:hover {
  background-color: #d7c0a8;
}

.candidates-list {
  max-height: 200px; /* Adjusted to show scrollbar sooner */
  overflow-y: auto;
}

.send-interview-button {
  padding: 10px 20px;
  border: none;
  background-color: #FF6B19;
  color: white;
  border-radius: 5px;
  cursor: pointer;
   margin-top: 20px; /* Spacing from the section */
  align-self: flex-end; /* Align to the right */
}

.send-interview-button:hover {
  background-color: #e65a00;
}

.add-candidate-row {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: sticky; /* Fix the position */
  bottom: 0; /* Stick to the bottom */
  background-color: #F9F3E4; /* Match the background */
  padding: 10px 0; /* Add some padding */
  z-index: 1; /* Ensure it stays on top */
}

.add-candidate-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.small-outreach-button {
  background-color: #FF6B19;
  border: none;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
}

.small-outreach-button:hover {
  background-color: #e65a00;
}

