@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.upload-resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center items vertically */
  height: 100vh;
  background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
  font-family: Arial, sans-serif; /* Use the same font as the welcome page */
  text-align: center; /* Center text */
  padding: 0 20px; /* Add padding for better text alignment */
  position: relative; /* Ensure the container is the reference for absolute positioning */
}

.job-title-title {
  font-size: 3.5em;
  color: white;
  margin-bottom: 40px; /* Add margin to position the text above the button */
  max-width: 610px; /* Set a maximum width for the text */
  margin: 0 auto; /* Center the text */
  word-break: keep-all; /* Ensures words do not break */
  opacity: 1;
  transition: opacity 1s;
}

.upload-resume-title {
  font-size: 3.5em;
  color: white;
  margin-bottom: 40px; /* Add margin to position the text above the button */
  max-width: 650px; /* Set a maximum width for the text */
  margin: 0 auto; /* Center the text */
  word-break: keep-all; /* Ensures words do not break */
  opacity: 1;
  transition: opacity 1s;
}

.fade-in-char {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  opacity: 0;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Ensure the inputs container does not affect the title position */
  opacity: 1;
  transition: opacity 1s;
  position: absolute; /* Position the inputs container absolutely */
  bottom: 125px; /* Adjust as needed to position the inputs container */
}

.upload-resume-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #fd7070;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
}

.upload-resume-button:hover {
  background-color: #ff4646;
}

.upload-resume-button.fade-out {
  animation: fadeOut 0.5s forwards;

}

.upload-resume-input {
  width: 300px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  font-size: 1em; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center items vertically */
  font-family: Arial, sans-serif; /* Use the same font as the welcome page */
  text-align: center; /* Center text */
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
}

.jobtitle-next-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #dd5050;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center; /* Center the text inside the button */
  /* Move the button below upload resume input */
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.jobtitle-next-button:hover {
  background-color: #df2626;
}
