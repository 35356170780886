.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable smooth horizontal and vertical scrolling */
}

/* Apply Poppins font with bold weight to specific elements */
.business-plan h1, .account-info h1, .account-info p {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: left;
}

.account-info h1, .business-plan h1 {
  color: #333;
  font-size: 36px;
  margin-bottom: 10px;
}

.account-info p {
  color: #777;
  font-size: 18px;
  margin-bottom: 30px;
}

/* Apply Roboto font to all other elements */
.business-plan ul, .input-group label, .input-group input, .save-btn, .logout-btn {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}


.account-page {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  min-width: 1200px;
  min-height: 800px;
  background: linear-gradient(to top, #FF6c94, #feaC6e);
  padding: 20px;
  box-sizing: border-box;
}

.account-info {
  width: 30%;
  padding: 30px;
  border-radius: 10px;
  margin-top: 100px;
  margin-left: 175px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
  min-height: 500px;
  background: transparent;
  box-sizing: border-box;
  overflow: visible;
}

.account-info h1 {
  text-align: center;
}

.account-info p {
  text-align: center;
}

.business-plan {
  width: 40%;
  padding: 30px;
  background-color: #f7f7f7;
  border-radius: 10px;
  margin-top: 100px;
  margin-left: 165px;
  height: calc(100vh - 200px);
  min-height: 500px;
  margin-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.business-plan h1 {
  text-align: center;
}

.business-plan p:first-of-type {
  text-align: center;
  font-size: 20px;
  margin: 0 auto 20px;
}

.plan-price {
  font-size: 48px;
  font-weight: bold;
  color: #ff9f43;
  margin-bottom: 20px;
  text-align: center;
}

.plan-price span {
  font-size: 20px;
  font-weight: normal;
  color: #777;
}

.business-plan ul {
  list-style-type: disc;
  margin: 20px 0 0 40px;
  padding: 0;
  font-size: 24px; /* Slightly increased font size */
  color: #333;
  line-height: 2; /* Increased line height for better spacing */
}

.business-plan p:last-child {
  text-align: center;
  margin-top: 10px;
  color: #ff5c5c;
  font-size: 16px; /* Increased font size */
  margin-bottom: 30px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.save-btn {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.save-btn-disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.save-btn-enabled {
  background-color: green;
  color: white;
  cursor: pointer;
}

.logout-btn {
  background-color: #ff5c5c;
  color: white;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.logout-btn:hover {
  opacity: 0.8;
}

.business-plan .change-plan-btn {
  background-color: #ff9f43;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  align-self: center;
  margin-top: 20px;
}

.business-plan .change-plan-btn:hover {
  background-color: #e88a28;
}

.business-plan p:last-child {
  text-align: center;
  margin-top: 10px;
  color: #ff5c5c;
  font-size: 14px;
  margin-bottom: 30px;
}

/* Confirmation Popup Styles */
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.confirmation-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px;
}

.confirmation-buttons button {
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: gray;
} 

.logout-button {
  position: absolute;
  top: 17px;
  left: 10px;
  padding: 8px 15px;
  margin-left: 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: coral;
  color: white;
  cursor: pointer;
}

.logout-button:hover {
  background-color: darkorange;
}

.not-satisfied-text {
  text-align: center;
  color: #ff5c5c;
  font-size: 16px;
  cursor: pointer;
}

.not-satisfied-text:hover {
  text-decoration: underline;
}

.cancel-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.popup-content-account-page {
  background: #fef6e3;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
}

textarea {
  width: 100%;
  height: 100px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.button-group {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Space between the buttons */
  margin-top: 10px;
}

.cancel-membership-btn,
.close-popup-btn {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-align: center;
  height: 45px; /* Ensure both buttons have the same height */
}

.cancel-membership-btn {
  background-color: red;
  color: white;
}

.close-popup-btn {
  background-color: gray;
  color: white;
}
