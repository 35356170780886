.main-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.ide-container {
    display: flex;
    flex-direction: column;
    height: 80vh; 
    width: 80vw;
}

.editor-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; 
}

.terminal-container {
    height: 200px;
    width: 80vw; 
    background-color: black;
}

.editor-container .monaco-editor {
    flex-grow: 1;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.title {
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.button-container {
    display: flex;
    justify-content: space-between; /* Adjust based on desired spacing */
    align-items: center; /* Aligns items vertically at the center */
    width: 100%;
    margin-bottom: 15px;
}
  
.run-button {
    background-color: green;
    border: none;
    color: white;
    padding: 15px 32px;
    font-size: 12px;
    margin-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
  
.run-button:hover {
    background-color: darkgreen;
}
  
.end-button {
    background-color: red;
    border: none;
    color: white;
    padding: 15px 32px;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
  
.end-button:hover {
    background-color: darkred;
}
  
/* Ensure the container is scrollable and does not shrink the bubbles */
.example-container {
    width: 20vw;
    height: 100%;
    background-color: #e0e0e0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 30px;
    overflow-y: auto; /* Ensure vertical scrolling */
    overflow-x: hidden;
}

/* Ensure bubbles maintain their size and do not shrink */
/* .bubble {
    background-color: white;
    border-radius: 15px;
    width: 95%;
    padding: 10px;
    margin: 10px 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    word-wrap: break-word;
    white-space: normal;
    position: relative;
    min-height: 40px;
    flex-shrink: 0;
}

.bubble.user {
    background-color: #FF6B19; 
    color: black; 
} */

/* Make the main content container scrollable */
.content-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 150px);
    width: 100%;
    overflow: hidden; /* Prevent the container from stretching */
}
.terminal-container, .editor-container {
    flex-shrink: 0; /* Prevent them from shrinking */
}


.example-container::-webkit-scrollbar {
    width: 8px;
}

.example-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.example-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1001; /* Ensure the button is on top of other elements */
    color: black; /* Ensure the button is visible */
}

.skip-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 5px 10px;
    background: white;
    color: black;
    border: 1px solid lightgray;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.skip-button:hover {
    background: #999999;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative; /* Added to position close button correctly */
}

.feedback-button, .no-feedback-button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.feedback-button {
    background-color: #4CAF50;
    color: white;
}

.no-feedback-button {
    background-color: #f44336;
    color: white;
}

.description {
    margin-top: 5px;
    font-size: 12px;
    color: #555;
}

.close-button-x {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001; /* Ensure the button is on top of other elements */
    cursor: pointer;
}
