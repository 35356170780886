@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
  font-family: Arial, sans-serif;
}

.welcome-text {
  font-size: 4em;
  color: white;
  text-align: center;
}

.fade-in-char {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}
.welcome-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Ensure text alignment is centered */
}

.terms-container {
  margin-top: 20px; /* Add spacing from the text above */
  opacity: 0; /* Start hidden */
  animation: fadeIn 1s forwards; /* Fade in animation */
}