/* PreviewPopup.css */
.preview-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.intro-input {
  margin-bottom: 10px;
  width: 100%;
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
}
.preview-popup-content {
  background-color: #fff8e3 !important;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 650px;
  height: 85vh; /* Match the height of AddJobPopup */
  position: relative;
  margin-top: 60px; /* Matches the shift of AddJobPopup */
  overflow-y: auto; /* Enable vertical scrolling */
}

.popup-heading {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px; /* Make the main heading smaller */
  color: #333;
  margin-bottom: 10px;
  text-align: left;
}

.popup-subheading {
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px; /* Make the subheadings smaller */
  color: #333;
  margin-top: 20px;
}

.popup-description {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
}

.questions-list {
  margin-bottom: 20px;
}

.question-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.question-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-right: 10px;
}

.remove-question-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #ff6b19;
}

.add-question-button {
  background-color: #ff6b19;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin-bottom: 20px;
  width: 100%;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

button.close-button, 
button.create-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  width: 48%;
  text-align: center;
  box-sizing: border-box;
  height: 50px;
}

button.close-button {
  background-color: #f8d7da;
  color: #721c24;
}

button.create-button {
  background-color: #ff6b19;
  color: white;
}

button.close-button:hover {
  background-color: #f5c6cb;
}

button.create-button:hover {
  background-color: #e55a17;
}
