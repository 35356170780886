.profile-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.profile-popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 400px;
  position: relative;
}

.profile-popup-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.profile-field {
  margin: 10px 0;
  text-align: left;
}

.profile-field label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-field input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.profile-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.save-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: gray;
  color: white;
  cursor: not-allowed;
}

.save-button.enabled {
  background-color: green;
  cursor: pointer;
}

.save-button.enabled:hover {
  background-color: darkgreen;
}

.close-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: red;
  color: white;
  cursor: pointer;
}

.close-button:hover {
  background-color: darkred;
}

.cancel-membership-button {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: red;
  color: white;
  cursor: pointer;
}

.cancel-membership-button:hover {
  background-color: darkred;
}

.logout-button {
  position: absolute;
  top: 17px;
  left: 10px;
  padding: 8px 15px;
  margin-left: 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: coral;
  color: white;
  cursor: pointer;
}

.logout-button:hover {
  background-color: darkorange;
}

.change-password-button {
  margin-top: 15px; /* Add some space from the Cancel Membership button */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: orange;
  color: white;
  cursor: pointer;
}

.change-password-button:hover {
  background-color: darkorange;
}

/* X button in the top-right corner */
.close-x-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 12px 20px; /* Increased padding */
  font-size: 24px; /* Increased font size */
  border: none;
  border-radius: 50%;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.close-x-button:hover {
  background-color: lightgray;
}

/* Save Confirmation Popup */
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.confirmation-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px; /* Added gap between buttons */
}

.confirmation-buttons button {
  padding: 6px 12px;  /* Reduced padding for smaller buttons */
  font-size: 14px;  /* Smaller font size for buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: gray;
}

.proceed-button {
  background-color: green;
  color: white;
}

.cancel-button:hover {
  background-color: darkgray;
}

.proceed-button:hover {
  background-color: darkgreen;
}

/* Change Password Popup Styles */
.confirmation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.confirmation-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 15px; /* Added gap between buttons */
}

/* Smaller buttons inside Change Password popup */
.confirmation-buttons button {
  padding: 4px 20px;  /* Reduced padding for smaller buttons */
  margin-left: 1px;
  font-size: 14px;  /* Smaller font size */
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: gray;
}

.proceed-button {
  background-color: green;
  color: white;
}

.cancel-button:hover {
  background-color: darkgray;
}

.proceed-button:hover {
  background-color: darkgreen;
}
