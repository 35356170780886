.candidate-result-public {
    position: relative;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: #fff8e3; /* Same color as businessSidebar */
    display: flex;
    flex-direction: column;
    padding: 40px;
    box-sizing: border-box;
    overflow-y: auto; /* Allow vertical scrolling */
    margin: 3pc auto 0;
  }
  
 
  .candidate-info-header-public {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .overall-score-public {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .overall-circle-public {
    background-color: #FFE4C7;
    color: #000;
    font-weight: bold;
    width: 120px;
    height: 120px;
    font-size: 2em;
  }
  
  .horizontal-circles-public {
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin-top: 20px;

  }
  .circle-with-word {
    margin: 20px;
  }
  
  .feedback-content-public,
  .candidate-info-transcript-public {
    margin: 20px 0;
  }
  
  .bubbletemplate-public {
    background-color: white;
    padding: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 100%;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .bubbletemplate-public.ai {
    align-self: flex-start;
    background-color: #e0e0e0;
    border-radius: 0 10px 10px 10px;
    max-width: 40%;
  }
  
  .bubbletemplate-public.human {
    align-self: flex-end;
    background-color: #FF6B19;
    color: white;
    border-radius: 10px 0 10px 10px;
    margin-left: calc(60% - 1px);
  }
  
  .video-popup-public {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .video-popup-content-public {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
  }