* {
  box-sizing: border-box;
}

.int-video-container {
  flex-grow: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thinking-dots {
  position: absolute;
  width: 100%;
  font-size: 5rem; /* Increased font size for bigger dots */
  text-align: center;
  color: black;
  top: 46%;
  left: 50.5%;
  transform: translate(-50%, -50%);
  font-family: 'Comic Sans MS', 'Comic Sans';
  visibility: hidden;
}

.thinking-dots::after {
  content: "..."; /* Use middle dots for circular appearance */
}

.thinking-dots.ai-speaking {
  color: lime;
}

.thinking-dots.blinking::after {
  animation: blink 1s infinite;
}

/* Optional: Add a slight letter spacing to make the dots more spaced out */
.thinking-dots::after {
  letter-spacing: 0.2em;
}

@keyframes blink {
  0% { opacity: .2; }
  20% { opacity: 1; }
  100% { opacity: .2; }
}

.user-video-wrapper {
  position: absolute;
  bottom: calc(18% - 10px); 
  left: calc(20% + 40px); 
  width: 150px; 
  height: 150px; 
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  z-index: 10;
}

.user-video-wrapper.mic-active {
  border-color: #00ff00; /* Green border color */
}

.user-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);

}

.timer-container {
  position: absolute;
  bottom: 30px; /* Moved up slightly to accommodate larger timer bar */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.end-interview-button {
  position: fixed;
  bottom: 30px; /* Aligned with the bottom of the timer container */
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff4136;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.end-interview-button:hover {
  background-color: #ff1a1a;
}

.end-interview-button i {
  font-size: 24px;
}

.loading-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
}

.loading-icon {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin: 0 auto 20px;
}

.start-prompt {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  z-index: 1000;
}

.spoken-word {
  font-size: 1.2rem;
  margin-right: 0.2rem;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
.spoken-word.active {
  opacity: 1;
}
.help-button {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 36px;
  color: #3498db;
  cursor: pointer;
  z-index: 1000;
  visibility: visible;

}
.help-menu {
  position: fixed;
  top: 60px;
  right: 20px;
  width: 300px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.help-menu h2 {
  margin-bottom: 10px;
  font-size: 1.25rem;
}
.help-menu ul {
  list-style-type: none;
  padding-left: 0;
}
.help-menu ul li {
  margin-bottom: 10px;
}
.error-textarea {
  width: 100%;
  height: 80px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
}
.submit-error-button {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.submit-error-button:hover {
  background-color: #2980b9;
}
.success-message {
  margin-top: 10px;
  color: green;
  font-size: 14px;
  text-align: center;
}

.slider-container-interview {
  position: fixed;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20vw; /* 1/8 of the screen width */
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 998;
  font-family: Arial, sans-serif;
}

.slider-bar-interview {
  width: 100%;
  height: 10px;
  background: rgba(255, 250, 244, 0.4);
  border-radius: 20px;
  position: relative;
  margin-bottom: 5px;
}

.slider-knob-interview {
  width: 6px;
  height: 6px;
  background: #000000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: left 0.3s ease;
}

.slider-knob-interview.listening {
  left: 1%;
}

.slider-knob-interview.thinking {
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-knob-interview.speaking {
  left: 99%;
  transform: translate(-100%, -50%);
}

.slider-labels-interview {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 10px;
  color: black;
  font-style: italic;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 768px) {
  .slider-labels-interview {
    font-size: 6px; 
  }
}

.camera-choice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent dark overlay */
  backdrop-filter: blur(8px); /* Apply the blur effect */
  z-index: 999; /* Ensure it's above other content */
}

.camera-choice-popup {
  background-color: #FFF8E7; /* Light beige */
  border: 1px solid #D4AF37; /* Gold border */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
  padding: 20px 30px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.interview-popup-content h2 {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 15px;
}

.interview-popup-content p {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.interview-popup-content button {
  width: 100px;
  padding: 10px 15px;
  font-size: 1em;
  margin: 0 10px; /* Add spacing between buttons */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.interview-popup-content button:first-of-type {
  background-color: #4CAF50; /* Green */
  color: white;
}

.interview-popup-content button:first-of-type:hover {
  background-color: #45a049;
}

.interview-popup-content button:last-of-type {
  background-color: #f44336; /* Red */
  color: white;
}

.interview-popup-content button:last-of-type:hover {
  background-color: #d42d2d;
}
