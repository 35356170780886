.upload-emails-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 80%;
  max-width: 600px;
  max-height: 70vh;
  overflow-y: auto;
}

.upload-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h2 {
  margin-bottom: 20px;
}

input[type="file"] {
  margin-bottom: 20px;
}

select {
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid black;
  width: 100%;
  max-width: 400px;
}

.button-container {
  display: flex;
  gap: 10px;
}

.submit-button,
.close-upload-popup-button {
  padding: 10px 20px;
  border: 1px solid black;
  background-color: transparent;
  cursor: pointer;
}

.submit-button:hover,
.close-upload-popup-button:hover {
  background-color: #f0f0f0;
}

.individual-entries {
  margin-top: 20px;
  width: 100%;
}

.individual-entry {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.individual-entry input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: calc(50% - 15px);
}

.individual-entry button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.individual-entry button:hover {
  background-color: #d32f2f;
}

.add-entry-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.add-entry-button {
  background-color: #f0f0f0;
  color: #4CAF50;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.add-entry-button i {
  font-size: 20px;
}

.add-entry-button:hover {
  background-color: #e0e0e0;
}

.email-sent-message {
  margin-top: 20px;
}
