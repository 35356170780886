/* Overlay to cover the screen */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Main popup container */
.popup-content {
  background-color: #fff8e3 !important; /* Forceful beige background color */
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 650px;
  max-height: 85vh;
  overflow-y: auto;
  position: relative;
  margin-top: 60px; /* Shift the popup down */
}

/* Headings for Add Job Details and Add Scoring Criteria */
.popup-heading {
  margin-top: 0;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 20px; /* Adjusted to look more like a heading */
  color: #333;
  text-align: left; /* Left-aligned */
  margin-bottom: 15px;
}

/* Input fields styling */
.job-title-input {
  width: 50%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 10px; /* Slightly more rounded corners */
  font-size: 15px; /* Slightly smaller font size */
  font-family: 'Arial', sans-serif;
}

/* Input fields styling */
.job-description-input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 10px; /* Slightly more rounded corners */
  font-size: 15px; /* Slightly smaller font size */
  font-family: 'Arial', sans-serif;
}

.job-description-input {
  height: 100px;
}

/* Scoring Criteria Section */
.scoring-criteria-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.label-text {
  font-family: 'Arial', sans-serif;
  font-size: 14px; /* Slightly smaller font size to match the image */
  font-weight: normal; /* Remove bold effect */
  color: #333;
  text-align: center; /* Center-align the text */
  flex: 2; /* Ensure equal spacing for Skill and Weighting labels */
  padding-left: 80px; /* Adjust this value to move the labels slightly to the right */
}

.skill-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px; /* Added margin between skill and weighting fields */
}

.skill-input {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px; /* Slightly more rounded corners */
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  margin-right: 10px; /* Added margin between skill and weighting inputs */
}

.weighting-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px; /* Slightly more rounded corners */
  text-align: center;
  font-size: 14px;
  font-family: 'Arial', sans-serif;
}

/* Buttons container */
.popup-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; /* Adjusted margin-top for buttons */
}

/* Close and Next button styling */
button.close-button, 
button.next-button {
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-family: 'Arial', sans-serif;
  font-weight: 600; /* Bold font for buttons */
  font-size: 16px;
  width: 48%;
  text-align: center;
  box-sizing: border-box;
  height: 50px;
}

button.close-button {
  background-color: #f8d7da;
  color: #721c24;
}

button.next-button {
  background-color: #ff6b19;
  color: white;
  margin-top: 1px; /* Shift the next button up */
}

/* Hover effects */
button.close-button:hover {
  background-color: #f5c6cb;
}

button.next-button:hover {
  background-color: #e55a17;
}

/* Close icon at the top */
.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.job-title-time-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}

.time-input-container {
  display: flex;
  flex-direction: column; /* Ensure the label and input are in a column */
  align-items: flex-end; /* Align the content to the right */
}

.time-limit-input {
  width: 50px;
  height: 50px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 15px;
}

.popup-heading-time {
  font-family: 'Arial', sans-serif;
  font-weight: 500;
  font-size: 15px; /* Adjusted to look more like a heading */
  color: #333;
  text-align: left; /* Left-aligned */
  margin-top: -21px;
}

.time-unit {
  margin-left: 2px;
  font-size: 12px;
  color: #333;
}