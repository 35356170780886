@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body.results-page {
    height: 100%; 
    overflow: hidden;
    /*font-family: 'Jost', sans-serif;*/
}

.results-container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #FFE4C7;
    height: calc(100vh - 60px);
}

.results-section {
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: space-between;
}

.results-title-box {
    background-color: #F9F3E4;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: auto;
}

.results-title-box h1 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
}

.feedback-box {
    background-color: #F9F3E4;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centers the circle vertically */
    align-items: center;      /* Centers the circle horizontally */
    max-height: calc(100vh - 200px);
    position: relative;
}

.feedback-box h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    position: absolute;
    top: 20px; /* Keeps text at the top */
    z-index: 1; /* Ensure text stays on top of the circle */
}

.average-score-circle {
    width: 50vmin;               /* Proportional circle size */
    height: 50vmin;              /* Maintain a perfect circle */
    background-color: #f4d1a1;   /* Light beige color */
    color: white;                /* White text */
    border-radius: 50%;          /* Make it circular */
    display: flex;
    justify-content: center;     /* Center the text horizontally */
    align-items: center;         /* Center the text vertically */
    font-size: 8vmin;            /* Proportional font size */
    font-weight: bold;
    z-index: 0;                  /* Circle stays behind the text */
    margin-top: 60px;            /* Adds margin to prevent overlap */
}

.feedback-box p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    text-align: center;
}

.score-container {
    width: 38%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.button-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

button_results {
    padding: 15px;
    font-size: 1.2rem;
    background-color: #f9f3e4;
    color: #333; 
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 100%;
    font-weight: bold;
}

.results_page_button:hover {
    background-color: #e7d9be;
}

.results_page_button.selected {
    background-color: #ff7043;  /* Orange color for selected buttons */
    color: white;
}

.next-btn {
    background-color: #28a745;
    color: white;
    justify-content: center;
    font-weight: bold;
}

.next-btn:hover {
    background-color: #218838;
}


.feedback-inner-box {
    margin-top: 35px;
    overflow: scroll;
   }

@media (max-width: 768px) {
    html, body {
        overflow: scroll;
    }

    .results-container {
        flex-direction: column;
        padding: 10px;
        padding-top: 60px;
        height: auto;
    }
    
    .results-section,
    .score-container {
        width: 100%;
    }
    
    .feedback-box {
        padding-top: 100px; /* Add more space to prevent overlap */
    }

    .score-circle {
        width: 60vmin;   /* Adjust circle size on smaller screens */
        height: 60vmin;  /* Maintain perfect circle */
        font-size: 10vmin; /* Adjust font size proportionally */
    }

    .score-container {
        margin-top: 20px;
    }
}
