.settings-popup-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.add-job-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 30%; /* Adjust width as needed */
  background-color: #F9F3E4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 0 20px; /* Add margin to separate from other sections */
  flex-grow: 1; /* Allow the box to stretch */
  overflow-y: auto;
}

.positions-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  width: 30%; /* Adjust width as needed */
  background-color: #F9F3E4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin: 0 20px; /* Add margin to separate from other sections */
}

.add-job-section, .settings-section {
  width: 100%;
}

.add-job-section h3, .settings-section h2 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #333;
}

.add-job-section form, .settings-section ul {
  display: flex;
  flex-direction: column;
}

.add-job-section input, .settings-section input, .add-job-section textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-job-section textarea {
  resize: vertical;
  height: 100px;
}

.add-job-section button, .settings-section button {
  padding: 10px;
  border: none;
  background-color: #FF6B19;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.add-job-section button:hover, .settings-section button:hover {
  background-color: #e65a00;
}

.checkbox-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-section input {
  margin-right: 10px;
}

.custom-questions-section-settings {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start */
  margin-bottom: 10px;
}

.custom-questions-section-settings h4 {
  margin: 0;
}

.custom-question {
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 100%; /* Ensure the custom question takes full width */
}

.custom-question input {
  flex-grow: 1; /* Allow the input to take up available space */
  padding: 10px;
  margin-right: 10px; /* Add some space between the input and the trash icon */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-question-button-settings {
  background-color: #FF6B19;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center the plus icon */
  margin-top: 10px; /* Add some space above the button */
}

.add-question-button-settings:hover {
  background-color: #e65a00;
}

.personality-traits-section {
  margin-bottom: 10px;
}

.personality-traits-section h4 {
  margin-bottom: 10px;
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.traits-grid input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.settings-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.job-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc; /* Add border between items */
}

.job-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.trash-icon {
    color: red;
    cursor: pointer;
}

.confirm-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.confirm-popup-content {
  text-align: center;
}

.confirm-delete-button,
.cancel-delete-button {
  margin: 10px;
}