.help-popup-container {
  padding: 20px;
  text-align: left;
}

.help-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.help-form {
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.help-input {
  width: 30%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 35px;
}

.help-textarea {
  width: 100%;
  height: 25vh;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.help-submit-button {
    padding: 5px 10px; /* Smaller padding */
    border: none;
    border-radius: 4px;
    background-color: #FF6B19;
    color: white;
    cursor: pointer;
    align-self: flex-start; /* Align to the left */
  }
  
  .help-submit-button:hover {
    background-color: #e65a00;
  }