 /* MAIN ELEMENTS */
 
 .welcome-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to top, #fc7cab 0%, #ff9061 50%, #ffcc66 100%);
  }
  
  .welcome-text {
    font-size: 4em;
    color: white;
    text-align: center;
  }
  
  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .input-field {
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border-radius: 20px;
    border: none;
    font-size: 1em;
    text-align: center;
    margin-top: 10px;
  }

  .interview-next-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: gray; 
    border: none;
    border-radius: 20px;
    color: white;
    cursor: not-allowed; 
    transition: background-color 0.3s, cursor 0.3s;
    max-width: 100px;
    text-align: center;
    margin: 20px auto;
  }
  
  .interview-next-button:enabled {
    background-color: #dd5050;
    cursor: pointer;
  }

  .interview-next-button:hover {
    background-color: #df2626;
  }

/* NAME & EMAIL PAGE */

  .interview-next-button.name-email {
    cursor: pointer;
  }

/* UPLOAD RESUME PAGE */

.loading-icon {
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

.upload-resume-title {
  font-size: 3.5em;
  color: white;
  margin: 0 auto; 
  word-break: keep-all; 
  opacity: 1;
  transition: opacity 1s;
}


.resume-inputs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  opacity: 1;
  transition: opacity 1s;
  position: absolute; 
  bottom: 125px;
}

.upload-resume-input {
  width: 300px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  font-size: 1em; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  text-align: center;
  position: absolute;
  bottom: 160px;
  left: 50%;
  transform: translateX(-50%);
}

.upload-resume-checkbox {
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
}

.interview-next-button.upload-resume {
  background-color: #fd7070;
  cursor: pointer;
}

.interview-next-button.upload-resume:hover {
  background-color: #ff4646;
}

.interview-next-button.upload-resume.fade-out {
  animation: fadeOut 0.5s forwards;
}

.uploadresume-next-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #fd7070;
  color: white;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  word-break: keep-all;
}

.uploadresume-next-button:hover {
  background-color: #ff4646;
}

.upload-resume-title, .upload-resume-subtitle {
  margin-top: 50px;
  display: inline-block;
  white-space: pre-wrap;
  color: white;
}

/* GET READY PAGE */

.welcome-text.get-ready {
  font-size: 3em;
}

.permissions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  width: auto;
  margin-bottom: 20px;
}

.permission-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  width: 100%; 
}

.toggle-label {
  color: black;
  margin: 10px auto;
  font-weight: bold;
  text-align: right;
  flex: 1;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 20px;
  margin-left: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.terms-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.7em;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.audio-square {
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid black;
  border-radius: 10px;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.audio-square.active {
  transform: scale(1.1);
}

.audio-visualization {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.status-message {
  margin-top: 10px;
  color: red;
  font-weight: bold;
  font-size: 0.7em;
  text-align: center;
}

.status-message > div.invisible {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.status-message > div.visible {
  opacity: 1;
}

/* INTERVIEW PAGE */

.interview-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: radial-gradient(circle at center,
    #ff8080 0%,
    #fd8d8d 15%,
    #fba989 25%,
    #FFFAF4 35%   
  );
  overflow: hidden;
}

.timer-text {
  color: black;
  font-size: 36px; 
  font-weight: bold;
}

.timer-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background-color: rgba(255, 140, 0, 0.3);
  z-index: 20;
}

.timer-bar-progress {
  height: 100%;
  background-color: #FF8C00; 
  transition: width 1s linear;
}

.ai-speaking-text {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  font-size: 1.5rem;
  color: #ff4b4b;
  text-align: center;
  z-index: 1000;
  line-height: 1.2;
}

/* INTERVIEW COMPLETED PAGE */

.interview-done-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background: linear-gradient(to top, #fc7cab, #ff9061, #ffcc66);
  font-family: 'Arial', sans-serif;
  animation: fadeIn 1s ease-in-out;
}

.interview-content {
  background-color: rgba(255, 255, 255, 0.1); 
  padding: 20px;
  border-radius: 10px;
  color: white;
  max-width: 600px;

  h1 {
    font-size: 3em;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  p {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
}

/* ANIMATION EFFECTS */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fade-in-char {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 1s forwards;
}