.watch-demo-button {
    border: none;
    background: linear-gradient(135deg, #FF6B19, #FF6B6B, #FFCC70, #FFB347, #FF8C42);
    background-size: 400% 400%;
    box-shadow: 0 4px 15px rgba(255, 107, 107, 0.5), 0 4px 15px rgba(255, 107, 25, 0.5);
    animation: gradientAnimation 5s ease infinite, buttonEntrance 1s ease-out forwards;
    cursor: pointer;
    border-radius: 50px;
    margin-top: 30px;
    padding: 10px 20px;
    color: white;
    display: block; /* Ensure the button is a block element */
    margin-left: auto; /* Center the button */
    margin-right: auto; /* Center the button */
    transition: background 0.3s ease-in-out;
  }
  
  .watch-demo-button:hover {
    background: linear-gradient(45deg, #FFCC70, #FFB347, #FF8C42, #FF6B19);
  }
  .schedule-demo-button {
    border: none;
    background: linear-gradient(135deg, #FF6B19, #FF6B6B, #FFCC70, #FFB347, #FF8C42);
    background: linear-gradient(135deg, #000000, #5e2400);
  
    background-size: 400% 400%;
    box-shadow: 0 4px 15px rgba(255, 107, 107, 0.5), 0 4px 15px rgba(255, 107, 25, 0.5);
    box-shadow: 0 6px 19px rgba(0, 0, 0, 0.5);
  
    animation: gradientAnimation 5s ease infinite, buttonEntrance 1s ease-out forwards;
    cursor: pointer;
    border-radius: 50px;
    padding: 10px 20px;
    color: white;
    display: block; /* Ensure the button is a block element */
    margin-left: auto; /* Center the button */
    margin-right: auto; /* Center the button */
    transition: background 0.3s ease-in-out;
  }
  
  .schedule-demo-button:hover {
    background: linear-gradient(45deg, #FFCC70, #FFB347, #FF8C42, #FF6B19);
  }
  
  
  .youtube-iframe {
    width: 700px; /* Adjust width */
    height: 400px; /* Adjust height */
  }


.custom-video-position {
    margin-top: 0; /* Remove any margin */
    margin-left: 0; /* Remove any margin */
  }

  .videoTag {
    width: 100%;
    height: 100%; /* Cover the entire container */
    object-fit: cover; /* Ensures video covers the area without stretching */
     /* Define the animation */
  }

  
.scroll-arrow {
    position: absolute;
    bottom: 3rem;
    height: 4rem;
    left: 0;
    right: 0; /* Set both left and right to center horizontally */
    margin-left: auto; /* Center horizontally */
    margin-right: auto; /* Center horizontally */
    z-index: 2;
    animation: slideFadeInOut 2s cubic-bezier(.77,0,.175,1) infinite;
  }

  .final-grid-element {
    background: linear-gradient(to bottom, navy 45%, rgba(142, 142, 0, 0.387) 70%, rgba(119, 77, 0, 0.39) 85%, rgba(78, 0, 0, 0.364) 100%);
    background-image: url('./lightattheend 1.png');
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Keeps the image centered */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh; /* Ensures it fits exactly one screen */
    overflow: hidden; /* Hides the parts of the image that go beyond the container */
  }
  
  .final-grid-element .video-wrapper {
    position: relative;
    z-index: 1;
    flex: 1 1 45%; /* Adjust the width of the video wrappers */
    max-width: 700px; /* Ensure the video wrappers do not exceed a certain width */
    width: 100%; /* Ensure both videos take the full width of their container */
    height: auto; /* Maintain aspect ratio */
  }

  .final-grid-element .play-button {
    display: none; /* Hide the play buttons */
  }


  .video-wrapper {
    position: relative;
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: white;
    cursor: pointer;
    z-index: 1;
    pointer-events: none;
  }

  .video-wrapper:hover .play-button {
    pointer-events: auto;
  }

  .video-container {
    position: relative;
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    width: 100vw; /* Full width */
    height: auto; /* Full height */
    aspect-ratio: 16 / 9;
    z-index: -1;
    overflow: hidden;
    filter: brightness(50%) sepia(80) saturate(10) hue-rotate(25deg) blur(10px);
    animation: focusVideo 2s ease-out forwards;
  }

  .video-container {
    position: relative;  /* or static */
    width: 100%;
    height: auto;        /* Let it size by the video’s natural ratio OR set your own hero height */
    z-index: -1;         /* If you want text on top */
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    .final-grid-element {
      display: flex;
      flex-direction: column; /* Stack videos vertically */
      align-items: center;
      justify-content: flex-start;
      height: auto;          /* Let it grow to fit content */
      overflow: visible;     /* Allow scrolling if needed */
    }
  
    .video-wrapper {
      margin: 20px 0; 
      width: 90%;           /* Or 100%, whichever suits you */
      max-width: 100%;
    }
  
    .youtube-iframe {
      width: 100%;          /* Take full width on mobile */
      height: auto;         /* Maintain aspect ratio */
    }
  }  

  .black-section-container {
    background: linear-gradient(180deg, black, #000030);
    position: relative;
    padding: 70px 20px; /* Example padding */
  }
  
  /* Each row is a horizontal flex container */
  .black-section-row {
    display: flex;
    align-items: center;  /* Vertically center text & video */
    justify-content: center; /* Space them, or use space-between, etc. */
    gap: 20px;            /* Space between text and video */
    margin-bottom: 60px;  /* Space between rows */
    margin-left: 50px;
    margin-right: 50px;
  }
  
  /* Title text styling */
  .black-section-text {
    flex: 1; /* Let it expand */
    text-align: center;
  }
  
  .black-section-title {
    color: #fff;                    
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 0;           /* Remove default heading margins */
    padding-bottom: 10px;
  }
  
  /* Video wrapper styling */
  .black-section-video-wrapper {
    flex: 1;             /* Same "weight" as text column */
    max-width: 600px;    /* Or 50%, etc. */
  }
  
  /* The video itself */
  .black-section-video {
    width: 100%;
    height: auto;
    border-radius: 20px; 
    box-shadow: 0 4px 15px rgba(0,0,0,0.6);
  }
  
  /* Responsive: stack them vertically on narrow screens */
  @media (max-width: 768px) {
    .black-section-row {
      flex-direction: column; 
      text-align: center;
    }
  
    /* Force text to come first, video second */
    .black-section-text {
      order: 1; 
      margin-bottom: 20px;
    }
  
    .black-section-video-wrapper {
      order: 2;
    }
  }
  

.mission-section {
  background-color: #f8efe4;
  padding: 60px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-container {
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  max-width: 1200px;
  width: 100%;
  align-items: flex-start;
  gap: 40px; /* Space between left and right sections */
}

.mission-left {
  flex: 1; /* Take up one part of the width */
  max-width: 40%; /* Ensure the left section is not too wide */
}

.mission-right {
  flex: 2; /* Take up more space for the description */
  max-width: 60%;
}

.mission-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.mission-subtitle {
  font-size: 2.2rem;
  font-weight: 600; 
  color: #000;
  margin-bottom: 20px;
}

.mission-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mission-container {
    flex-direction: column; /* Stack elements vertically on smaller screens */
    text-align: center; /* Center-align text */
  }

  .mission-left,
  .mission-right {
    max-width: 100%;
  }
}


/* The parent container that holds the video and the text/button */
.hero-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen hero (optional) */
  overflow: hidden; /* So the video doesn't cause scrollbars if it's bigger */
}

/* The video behind everything */
.hero-container .video-container {
  position: absolute; /* So it fills the hero container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* behind the text */
}

.hero-container .video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* fills the container nicely */
}

/* The superpowers text overlay */
.superpowers-text {
  position: absolute;   /* Let’s put it somewhere on top */
  top: 50%;            /* Center it vertically */
  left: 50%;           /* Center it horizontally */
  transform: translate(-50%, -50%);
  z-index: 2;          /* On top of the video */
  color: #fff;         /* White text over video */
  /* remove any large top padding from Tailwind e.g. pt-96! */
}

/***** AI Interview Section *****/

/* Full-height beige background with flex column layout */
.ai-interview-section {
  position: relative;
  background-color: #f8efe4;
  width: 100%;
  height: 100vh; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
}

/* Video wrapper: 70% viewport width, 16:9 aspect */
.ai-video-wrapper {
  position: relative;
  width: 70vw;
  max-width: 1200px;
  aspect-ratio: 16 / 9;
  background: #ccc; 
  overflow: hidden;
}
.ai-video-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Buttons row (below video) */
.ai-buttons-row {
  width: 70vw; 
  max-width: 1200px;
  display: flex;
  justify-content: space-between; 
  margin-bottom: 20px;
}
.ai-overlay-button {
  background-color: #000;
  color: #fff;
  border: 0.5px solid #fff;
  width: 50%;
  padding: 12px;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  transition: background 0.3s ease;
}
.ai-overlay-button:hover,
.ai-overlay-button.active {
  background: linear-gradient(45deg, #FFCC70, #FFB347, #FF8C42, #FF6B19);
}

/* ------------- */
/* Text Overlays */
/* ------------- */

/* Desktop overlay: absolutely positioned inside .ai-video-wrapper */
.ai-video-overlay-desktop {
  position: absolute;
  bottom: 10%;
  left: 7%;
  max-width: 27%;
  color: #000;
  background: transparent; /* Or rgba(255,255,255,0.7) if the video is dark */
}

/* Mobile overlay: normal static position below the buttons */
.ai-video-overlay-mobile {
  display: none; /* Hidden by default on desktop */
  width: 70vw;
  max-width: 1200px;
  color: #000;
  text-align: left; 
  /* If you want it left-aligned or center it with text-align: center */
}

/* Title + Description styling */
.ai-video-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: bold;
  border-bottom: 2px solid #000; 
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.ai-video-description {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
}

/* ---------- Mobile styles ---------- */
@media (max-width: 768px) {

  /* The entire section can be auto-height if desired */
  .ai-interview-section {
    height: auto;
    padding: 20px 0;
  }

  /* The video is narrower on phone */
  .ai-video-wrapper {
    width: 90vw;
    margin-bottom: 10px;
  }
  /* Buttons stack vertically */
  .ai-buttons-row {
    width: 90vw;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .ai-overlay-button {
    width: 100%;  
    margin-bottom: 10px;
  }

  /* Hide the desktop overlay, show the mobile overlay */
  .ai-video-overlay-desktop {
    display: none;  
  }
  .ai-video-overlay-mobile {
    display: block; /* Now it shows below the buttons */
  }

  /* Smaller text sizes on phone */
  .ai-video-title {
    font-size: 1.2rem;
  }
  .ai-video-description {
    font-size: 0.95rem;
  }
}
